.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: #2c2c2c;
  padding: 30px;
  border-radius: 10px;
  width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #fff;

  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    background-color: #333;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
    color: #fff;

    &:hover {
      background-color: #444;
    }

    &.selected {
      background-color: #007BFF;
      color: white;
    }
  }

  button {
    padding: 10px 20px;
    margin-top: 10px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;

    &:first-of-type {
      background-color: #4CAF50;
      color: #fff;
      margin-right: 10px;
    }

    &:last-of-type {
      background-color: #f44336;
      color: #fff;
    }
  }
}
