.App {
  text-align: center;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  &::before {
    content: '';
    background-image: url('./../public/background.webp');
    background-size: cover; // Or 'contain' based on your preference
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5; // Adjust the opacity as needed
    position: fixed; // Keeps the background fixed in the viewport
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; // Ensure it stays behind the content
  }
}

