.languageSelector {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  
  .languageButton {
    display: flex;
    align-items: center;
  }
  
  .languageIcon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
  
  .dropdownArrow {
    margin-left: 5px;
    font-size: 12px;
  }
  
  
  .languageDropdown {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    z-index: 1000;
    list-style-type: none;
    padding: 10px 0;
    min-width: 120px;
    border-radius: 4px;
  }
  
  .languageDropdown li {
    padding: 8px 16px;
    cursor: pointer;
    color: #333;
  }
  
  .languageDropdown li:hover {
    background-color: #f1f1f1;
  }
  