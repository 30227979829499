// Color palette
$primary-color: #4285F4; // Blue for primary actions
$secondary-color: #FFC107; // Amber for secondary elements
$dark-color: #333; // Dark gray for text and important elements
$medium-gray: #666; // Medium gray for less important text
$light-gray: #eee; // Light gray for backgrounds and borders
$text-color: #444; // Main text color
$error-color: #D32F2F; // Red for errors and warnings

// Typography
$font-main: 'Helvetica', Helvetica, Arial, sans-serif; // Helvetica Standard
$font-secondary: 'Roboto', sans-serif;

// Spacing
$base-spacing: 8px; // Base unit for margins and paddings
$large-spacing: 16px; // Larger spacing for layout separation
