.creditsContainer {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.creditsInfo {
  display: flex;
  align-items: center;
}

.creditIcon {
  width: 30px;
  height: 30px;
}

.creditsText {
  margin-left: 5px;
  font-weight: bold;
  font-size: 16px;
}

.dropdownArrow {
  margin-left: 5px;
  font-size: 12px;
}

.creditsDropdown {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1000;
  list-style-type: none;
  padding: 10px 0;
  min-width: 150px;
  border-radius: 4px;
}

.creditsDropdown li {
  padding: 8px 16px;
  cursor: pointer;
  color: #333;
  text-align: left;
}

.creditsDropdown li:hover {
  background-color: #f1f1f1;
}
