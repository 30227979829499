// Define variables
$font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
$font-stack-monospace: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
//$background-url: '../public/background.png'; // Adjust the path as necessary

// Apply global styles
body {
  margin: 0;
  padding: 0;
  font-family: $font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-image: url($background-url);
  //background-size: cover;
  //background-position: center;
  //background-repeat: no-repeat;
}

code {
  font-family: $font-stack-monospace;
}
