/* Assuming variables.scss includes color and font variables */
@import '../../variables.scss';

.sidebarOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; /* Full height */
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.sidebar {
  background-color: #ffffff;
  padding: 25px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow-y: auto;
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 20px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  color: $primary-color;
  transition: color 0.3s;

  &:hover {
    color: darken($primary-color, 10%);
  }
}

.heading {
  font-size: 1.8rem;
  color: $primary-color;
  margin-bottom: 15px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.subheading {
  font-size: 1.6rem;
  color: $secondary-color;
  margin-bottom: 10px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;
}

.section {
  margin-bottom: 25px;
}

.phraseContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15px;
}

.wordButton {
  margin: 4px;
  padding: 10px 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: #ffffff;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

/* Grammar classification styles */
.noun {
  background-color: #6c5ce7;
}

.verb {
  background-color: #00b894;
}

.adjective {
  background-color: #fdcb6e;
}

.particle {
  background-color: #00cec9;
}

.adverb {
  background-color: #e17055;
}

.defaultWord {
  background-color: #b2bec3;
}

.translatedText {
  font-weight: normal;
  color: $text-color;
  padding: 8px 0;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.5;
}

.wordDetails {
  margin-top: 20px;
}

.detailItem {
  margin: 8px 0;
  font-size: 1rem;
  line-height: 1.4;

  strong {
    color: $dark-color;
  }
}

.title {
  font-weight: bold;
  font-size: 1.4rem;
  color: $primary-color;
  margin-top: 15px;
}

.kanjiList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.kanjiDetails {
  display: flex;
  align-items: flex-start;
  margin: 15px 0;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #fafafa;
}

.kanjiCharacter {
  font-size: 3rem;
  color: $primary-color;
  margin-right: 15px;
}

.kanjiInfo {
  flex: 1;
}

.errorMessage {
  color: red;
  margin-bottom: 10px;
}
