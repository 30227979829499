.folder-card-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  position: relative;
}

.folder-card {
  position: relative;
  width: 200px;
  height: 300px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);

    .delete-icon {
      opacity: 1; /* Show the bin icon on hover */
    }
  }

  &.loading {
    justify-content: center;
    background-color: #f0f0f0;
    color: #999;
  }

  .folder-cover-image {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    object-position: center !important;
  }

  .loading-placeholder {
    font-size: 18px;
    color: #666;
  }

  .folder-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent grey background */
    color: white;
    text-align: center;

    h2 {
      font-size: 16px;
      margin: 0;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    p {
      font-size: 12px;
      margin: 5px 0 0;
    }
  }
}

.edit-icon {
  position: absolute;
  top: 10px;
  right: 40px; /* Position to the left of the delete icon */
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: 1;
  // transition: opacity 0.2s ease-in-out;

  // &:hover {
  //   opacity: 1;
  // }
}

.delete-icon {
  position: absolute;
  top: 10px;
  right: 10px; /* Adjusted from 10px to accommodate the edit icon */
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
}