.profileActions {
  position: relative; /* Ensure the dropdown is positioned relative to this container */
  display: inline-flex;
  align-items: center;
}

.profileAvatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.profileMenu {
  position: absolute;
  top: calc(100% + 10px); /* Position below the icon */
  right: 0; /* Align to the right of the parent container */
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0,0,0,0.15);
  border-radius: 4px;
  padding: 5px 0;
  list-style: none;
  z-index: 1000;
}


.profileMenu li {
  padding: 10px 20px;
  cursor: pointer;
  color: #333;
}

.profileMenu li:hover {
  background-color: #f8f8f8;
}
