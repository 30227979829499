.folderManager {
  padding: 20px;
  padding-top: 80px;
  display: flex;
  justify-content: center;
}

.folderGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
}
