.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: #2c2c2c;
  padding: 30px;
  border-radius: 10px;
  width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #fff;

  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .formGroup {
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 10px;
      font-size: 1.2rem;
      color: #fff;
    }

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #444;
      border-radius: 5px;
      background: #333;
      color: #fff;
      font-size: 1rem;
    }
  }

  .formActions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    background-color: #007BFF;
    color: #fff;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }

    &[type="submit"] {
      background-color: #4CAF50;
    }

    &[type="button"] {
      background-color: #f44336;
    }
  }
}

}
