/* AddFolderCard.module.scss */

.folderCardContainer {
  display: flex;
  flex-direction: column;
  margin: 10px;
  position: relative;
}

.folderCard {
  position: relative;
  width: 200px;
  height: 300px;
  background-color: #444; /* Match background color */
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  display: flex; /* Center the icon */
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.addFolderIcon {
  font-size: 4rem;
  color: #fff;
}
