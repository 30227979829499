/* TermsAndConditions.module.scss */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .termsContent {
    max-width: 800px;
    margin: 20px 0;
    text-align: left;
    line-height: 1.6;
    color: #333;
    overflow-y: auto;
    height: 60vh; /* Adjust height as needed */
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .section h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #444;
  }
  
  .section h3 {
    font-size: 1.2em;
    margin-bottom: 8px;
    color: #555;
  }
  
  .section p {
    margin-bottom: 10px;
  }
  
  .section ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .section ul li {
    margin-bottom: 5px;
  }
  
  .acceptButton {
    padding: 10px 20px;
    font-size: 1em;
    background-color: #4285F4;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .acceptButton:hover {
    background-color: #357AE8;
  }
  
  @media (max-width: 600px) {
    .termsContent {
      width: 100%;
      height: 50vh;
    }
  
    .acceptButton {
      width: 100%;
      font-size: 1.2em;
    }
  }
  