.phrases-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.phrase {
  position: absolute;
  background-color: rgba(128, 128, 128, 0.3);
  cursor: pointer;
}

.phrase:hover {
  background-color: rgba(128, 128, 128, 0.6);
}

.phrase.selected {
  background-color: rgba(128, 128, 128, 0.6);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
