/* General Header Styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 10px 20px;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  transform: translateY(0);
}

/* Header Visible State */
.headerVisible {
  transform: translateY(0);
}

/* Header Hidden State */
.headerHidden {
  transform: translateY(-100%);
}

/* Header Content Styles */
.headerContent {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 50px;
}

/* Button Styles */
.headerLogoButton {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 15px;
}

.logoImage {
  height: 50px;
}

.headerButton {
  padding: 8px 12px;
  margin-right: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.headerButton:hover {
  background-color: #0056b3;
}

/* Zoom Buttons Container */
.zoomButtons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 15px;
  flex-grow: 1;
}

.zoomButtons .headerButton {
  margin-right: 5px;
}

.version {
  margin: 0 10px;
  font-weight: bold;
}

.headerContent > * {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.zoomButtons {
  flex: 2 1 auto; /* Allow zoom buttons to take more space */
}

.headerContent > *:last-child {
  margin-right: 0;
}

/* Responsive Styles for Mobile Devices */
@media screen and (max-width: 768px) {
  .headerContent {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
  }

  .zoomButtons {
    margin-right: 0;
    margin-top: 10px;
    flex-grow: 0;
  }

  .headerContent > * {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .headerContent > *:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  .logoImage {
    height: 40px; /* Slightly smaller logo on mobile */
  }

  .headerButton {
    padding: 6px 10px; /* Smaller buttons on mobile */
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .version {
    margin: 0 5px;
    font-size: 0.9em;
  }
}
