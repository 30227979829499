.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.25);
}

.modalActions {
  margin-top: 20px;
  text-align: center;
}

.modalYes, .modalNo {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modalYes {
  background-color: #4CAF50;
  color: white;
}

.modalNo {
  background-color: #f44336;
  color: white;
}
