/* MangaImage.module.scss */

.manga-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; /* Adjust as needed for spacing between pages */
}

.image-overlay-container {
  position: relative;
}

.scan-button {
  margin-top: 10px; /* Space between the image and the button */
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.scan-button:hover {
  background-color: #0056b3;
}

.disabled-button {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.loading-message {
  margin-top: 10px;
}
