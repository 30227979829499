.manga-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width */
  overflow: hidden; /* Prevent overflow */
  padding-top: 60px; /* Adjust based on header's height */
  box-sizing: border-box;
}

.page-container {
  width: 100%;
  max-width: 100vw; /* Ensure the width doesn't exceed the viewport */
  flex-grow: 1;
  overflow: hidden; /* Prevent scrolling */
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

.header-button {
  padding: 8px 16px;
  margin-right: 10px;
  cursor: pointer;
}

.profile-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-menu {
  position: absolute;
  right: 20px;
  top: 60px;
  background: white;
  list-style: none;
  padding: 5px 0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  border-radius: 5px;
}

.profile-menu li {
  padding: 10px 20px;
  cursor: pointer;
}

