.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background to focus on the modal
  z-index: 1050; // High z-index to ensure it is above other content
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Soft shadow for a subtle depth effect
  width: 90%;
  max-width: 500px; // Maximum width to ensure it does not grow too large on big screens
  position: relative; // For absolute positioning of children like the close button

  h2 {
    margin-bottom: 20px; // Spacing under the header for better visual separation
  }

  .formGroup {
    margin-bottom: 20px; // Spacing between form controls for better readability
    label {
      display: block; // Ensures the label is on its own line
      margin-bottom: 5px; // Small space between label and input
    }

    select, input[type="checkbox"] {
      width: 100%; // Full width for better control and alignment
      padding: 8px; // Padding for easier interaction
      border: 1px solid #ccc; // Border to define the edges of the input
      border-radius: 4px; // Rounded corners for a softer look
    }
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px; // Larger font size for easy clicking
    color: #666; // Subtle color that should contrast with the background
  }
}
