.signInContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.signInTitle {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

.description {
  margin-bottom: 40px;
  text-align: center;
  color: #666;
  width: 80%;
  font-size: 16px;
}

.googleSignInButton {
  padding: 10px 20px;
  border: none;
  background-color: #4285F4;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #357AE8;
  }
}

.logo {
  max-width: 300px; // Adjust the size as needed
  margin-bottom: 20px; // Space between the logo and the title
}

